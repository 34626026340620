$(document).ready(() => {
    $(document).on('click', '#companySelectorDiv', () => {
        const $dropdown = $('#companyDropDown');
        if (!$dropdown.is(':visible')) {
            const $selector = $('#companySelectorDiv');
            $selector.css('border-bottom', '2px solid #d5d5d5');
            $dropdown.show().css({
                top: $('#topBar-ui2').height()
            });
            // Setting timer because the event is getting assigned too soon
            setTimeout(() => {
                $(document).one('click', () => {
                    $selector.css('border-bottom', '');
                    $('#companyDropDown').hide();
                });
            }, 100);
        }
    });
});
