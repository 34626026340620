$(document).ready(() => {
    let timeoutID;
    let chartTimeoutID;
    $(window).resize(() => {
        // Close any open submenus because they will need to be repositioned.
        $('.leftMenuItem.selected').removeClass('selected');

        // Re-align suggestSelectResults dropdown
        clearTimeout(timeoutID);
        if (resultsPanelOpen !== null) {
            timeoutID = setTimeout(() => {
                resultsPanelOpen.suggestselect('refresh');
            }, 500);
        }

        // Re-align charts in Reports > Maintenance Planner > Asset Labour Planning
        clearTimeout(chartTimeoutID);
        if ($('#heatMapRow').length) {
            chartTimeoutID = setTimeout(() => {
                $('#container-column').highcharts().reflow();
                $('#container-heatmap').highcharts().reflow();
                $('#container-heatmap-totals').highcharts().reflow();
            }, 500);
        }
    });

    $('#leftMenu-toggle-ui2').click(() => {
        const $body = $('body');
        if ($body.hasClass('menuExpanded')) {
            $body.removeClass('menuExpanded');
            document.cookie = 'leftMenuView=collapsed; SameSite=None; Secure'; // need to set SameSite attribute - https://www.chromestatus.com/feature/5088147346030592
        } else {
            $body.addClass('menuExpanded');
            document.cookie = 'leftMenuView=expanded; SameSite=None; Secure';
        }

        // Re-render charts if any
        // Setting delay for chart redraw as it does not render to updated view otherwise
        if (Highcharts.charts.length) {
            setTimeout(() => {
                // When a chart is destroyed, it remains in Highcharts array as undefined
                const allCharts = Highcharts.charts;
                for (let count = 0; count < allCharts.length; count += 1) {
                    if (typeof (allCharts[count]) !== 'undefined') {
                        allCharts[count].reflow();
                    }
                }
            }, 500); // Wait for the menu expand / collapse to complete.
        }
    });

    if ($('body').hasClass('menuClick')) {
        initClickEvent();
    } else {
        initHoverEvent();
    }
});

/**
 * Initialize events ot show submenu on hover
 * Using triangle effect, jQuery-menu-aim.js | https://github.com/kamens/jQuery-menu-aim
 */
function initHoverEvent() {
    $('#leftMenu-items-ui2')
        .menuAim({
            activate: selectMenuItem,
            deactivate: (row) => {
                // Cancel the opening of any menu that hasn't opened yet.
                cancelOpeningOfLeftMenu();

                // Close any open menu
                $(row).removeClass('selected');

                // Wait a bit to declare the menu closed.
                // Allows us to detect people switching between menus
                setLeftMenuClosedTimeout = setTimeout(() => {
                    leftMenuOpened = false;
                }, 75);
            },
            exitMenu: () => true,
            rowSelector: '.leftMenuItem'
        });
}

let leftMenuOpened = false;
let setLeftMenuClosedTimeout = null;
let openLeftMenuTimeout = null;

function selectMenuItem(item) {
    cancelClosingOfLeftMenu();
    cancelOpeningOfLeftMenu();

    if (leftMenuOpened) {
        // If there is already a menu open, open this next one immediately.
        openLeftMenuItem(item);
    } else {
        // No menu is open. Wait to make sure it wasn't triggered by accident.
        openLeftMenuTimeout = setTimeout(() => {
            openLeftMenuItem(item);
            leftMenuOpened = true;
        }, 250);
    }
}

function openLeftMenuItem(item) {
    const $item = $(item);
    if (!$item.hasClass('noSubmenu')) {
        $item.addClass('selected');
        setMenuPosition($item, $item.find('.leftMenu-submenu'));
    }
}

function cancelOpeningOfLeftMenu() {
    if (openLeftMenuTimeout) {
        clearTimeout(openLeftMenuTimeout);
        openLeftMenuTimeout = null;
    }
}

function cancelClosingOfLeftMenu() {
    if (setLeftMenuClosedTimeout) {
        clearTimeout(setLeftMenuClosedTimeout);
        setLeftMenuClosedTimeout = null;
    }
}

/**
 * Set the vertical position of a submenu, relative to the position of the selected left menu item.
 * Takes into account screen height and menu height.
 * @param $item The left menu item that was clicked.
 * @param $submenu The submenu to be positioned.
 */
function setMenuPosition($item, $submenu) {
    const SUBMENU_VERTICAL_OFFSET = -47;

    const $columnContainer = $submenu.find('.leftSubmenu-columnContainer');

    // Reset styles so we get the correct submenu height
    $submenu.css({
        top: '',
        bottom: ''
    });
    $columnContainer.css({
        overflowY: ''
    });

    const submenuHeight = $submenu.height();
    const windowHeight = $(window).height();

    // Ensure the top of the menu doesn't go off the top of the screen
    const topOffset = Math.max($item.position().top + SUBMENU_VERTICAL_OFFSET, 0);

    if (submenuHeight > windowHeight) {
        // Menu exceeds max height. Make it max height and the rest will scroll.
        $submenu.css({
            top: 0,
            bottom: 0
        });
        $columnContainer.css({
            overflowY: 'scroll'
        });
    } else if ((topOffset + submenuHeight) > windowHeight) {
        // Menu extends past the bottom of the screen. Fix it to the bottom and let it extend up.
        $submenu.css({
            bottom: 0
        });
    } else {
        // Menu is well behaved.
        $submenu.css({
            top: topOffset
        });
    }
}

/**
 * Initialize events to show submenu on click
 */
function initClickEvent() {
    $('.leftMenuItem')
        .on('click', function () {
            const $this = $(this);
            // Add/remove highlight when clicking menu item
            if ($this.hasClass('selected')) {
                $this.removeClass('selected');
            } else {
                $('.leftMenuItem.selected').removeClass('selected');
                openLeftMenuItem(this);
            }

            // When clicked away from the .leftMenuItem it should deselect the .leftMenuItem
            // Using setTimeout() as it gets adds and fires event straight away
            setTimeout(() => {
                $(document)
                    .one('click', (e) => {
                        if (e.target.closest('.leftMenuItem') == null) {
                            $('.leftMenuItem.selected').removeClass('selected');
                        }
                    });
            }, 100);
        });
}
